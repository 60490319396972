@import "_paddings";

@font-face {
  font-family: Comfortaa;
  src: url(../assets/fonts/Comfortaa-Regular.ttf);
  font-weight: normal;
}

@font-face {
  font-family: Comfortaa;
  src: url(../assets/fonts/Comfortaa-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Comfortaa;
  src: url(../assets/fonts/Comfortaa-Light.ttf);
  font-weight: 200;
}

@font-face {
  font-family: Comfortaa;
  src: url(../assets/fonts/Comfortaa-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Parisienne;
  src: url(../assets/fonts/Parisienne-Regular.ttf);
  font-weight: normal;
}

html {
  margin: 0;
  padding: 0;;
}

body {
  margin: 0;
  font-family: 'Comfortaa', -apple-system, BlinkMacSystemFont, sans-serif !important;
  font-size: 14px;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  border-color: lightgrey !important;
  color: #575454 !important;
  background: #e8eefa;
}

html, body {
  height: 100%;
}
