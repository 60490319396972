@import "./paddings.scss";
@import "./widths.scss";
@import "./alignments.scss";
@import "./tiptap.scss";

::placeholder {
  font-size: 14px !important;
}

a {
  color: #575454;
  text-decoration: none;
}

.rounded-container {
  border-radius: 0.5em !important;
}

.cursor-pointer {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

/*
* flex-direction
*/
.column {
  flex-direction: column !important;
}

.row {
  flex-direction: row !important;
}

.md-row {
  @media screen and (max-width: 1200px) {
    flex-direction: row !important;
  }
}

.md-column {
  @media screen and (max-width: 1200px) {
    flex-direction: column !important;
  }
}

.sm-row {
  @media screen and (max-width: 767px) {
    flex-direction: row !important;
  }
}

.sm-column {
  @media screen and (max-width: 767px) {
    flex-direction: column !important;
  }
}

/*
Border
*/
.border-bottom {
  border-bottom: 1px solid lightgrey;
}

/*
Margins
*/
.m-0 {
  margin: 0 !important;
}

.mt-1 {
  margin-top: 1em !important;
}

.mr-1 {
  margin-right: 1em !important;
}

.mb-1 {
  margin-bottom: 1em !important;
}

.h-fit-content {
  height: fit-content !important;
}

/*
React-Select
 */
.react-select-control {
  border-radius: 0.5em !important;
  height: 42px;
}

.react-select-control-focused {
  border-color: #2ce87e !important;
  box-shadow: rgba(44, 232, 126, 1) 0px 0px 0px 1px !important;
}

.react-select__option--is-focused {
  background-color: rgba(44, 232, 126, 0.3) !important; //= #2ce87e
}
